<template>
  <div>
    <div class="card cardcontents maincardlayer">
      <div class="card-header non-label-icon mapheader maincardheader text-white">
        통합개선 이행율 현황
        <!-- <div class="card-more" @click="goPage('/sop/ibm/imprActionRate')">more</div> -->
        <div class="card-more main-header-input">
          <c-datepicker
            :range="true"
            :appendToBody="false"
            defaultStart="-6M"
            defaultEnd="2M"
            type="month"
            name="actionCompleteRequestDates"
            v-model="grid.actionCompleteRequestDates"
            @datachange="getImprStatus"
          />
        </div>
      </div>
      <div class="card-body">
        <!-- <c-table
          ref="table5"
          title="개선 이행률 - 업무별"
          tableId="table5"
          :isTitle="false"
          :isDashboard="true"
          :columnSetting="false"
          :usePaging="false"
          :filtering="false"
          :isFullScreen="false"
          :hideBottom="true"
          :columns="grid.columns"
          :data="grid.data"
          gridHeight="350px"
          @linkClick="linkImprClick"
        /> -->
        <apexchart 
          ref="imprChart" 
          height="500px" 
          type="bar"
          :width="imprChart.chartWidth"
          :options="imprChart.chartOptions" 
          :series="imprChart.series"></apexchart>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'main-impr-rate',
  components: { 
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      // 차트
      imprChart: {
        chartOptions: {
          annotations: { 
            xaxis: [],
          },
          theme: {
            // mode: 'dark', 
            // palette: 'palette1', 
            // monochrome: {
            //   enabled: false,
            //   color: '#255aee',
            //   shadeTo: 'light',
            //   shadeIntensity: 0.65
            // },
          },
          title: {
            // text: '업무별 개선 현황'
          },
          chart: {
            type: 'bar',
            stacked: true,
            foreColor: "#fff",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          stroke: {
            show: true,
            // width: 2,
            // colors: ['transparent']
          },
          xaxis: {
            categories: [],
            // labels: {
            //   formatter: function (val) {
            //     return val + "건"
            //   }
            // }
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          colors: [
            // '조치중' '개선완료' '지연건수'
            '#FFC107', '#00BCD4', '#F44336'
          ],
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
        },
        series: [
          {
            id: 'actioning',
            name: '조치중',
            data: [],
          },
          {
            id: 'complete',
            name: '개선완료(총합)',
            data: [],
          },
          {
            id: 'overAction',
            name: '지연 건수',
            data: [],
          },
        ],
        chartWidth: '80%',
      },
      rate: [],
      grid: {
        listUrl: '',
        actionCompleteRequestDates: [],
        columns: [
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'requesting',
            field: 'requesting',
            label: '요청중',
            align: 'right',
            style: 'width:80px',
            sortable: false,
            type: 'cost',
          },
          {
            name: 'receipting',
            field: 'receipting',
            label: '조치부서 접수중',
            align: 'right',
            style: 'width:80px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'actioning',
            field: 'actioning',
            label: '조치부서 조치중',
            align: 'right',
            style: 'width:80px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'confirming',
            field: 'confirming',
            label: '요청부서 검토중',
            align: 'right',
            style: 'width:80px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'complete',
            field: 'complete',
            label: '개선완료',
            align: 'right',
            style: 'width:80px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'overAction',
            field: 'overAction',
            label: '지연 건수',
            align: 'right',
            style: 'width:80px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'immTotal',
            field: 'immTotal',
            label: '즉시처리건',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'cost',
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // url setting
      this.grid.listUrl = selectConfig.main.imprChart.url
      // list setting
      this.getImprStatus();
    },
    getImprStatus() {
      this.$http.url = this.grid.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.$store.getters.user.plantCd,
        actionCompleteRequestDates: this.grid.actionCompleteRequestDates,
        actionDeptCd: this.isManage ? '' : this.$store.getters.user.deptCd, // 해당 부분에 대한 문의 필요 이것 때문에 기술지도업체는 표시되지 않음
      }
      this.$http.request((_result) => {
        let taskIdx = 0;
        if (!this.isManage && _result.data && _result.data.length > 0) {
          _result.data = this.$_.reject(_result.data, { ibmTaskTypeCd: 'ITT0000145' })
        }
        this.$_.forEach(_result.data, task => {
          this.$_.forEachRight(this.imprChart.series, item => {
            if (!this.rate[taskIdx] && task[item.id] > 0) {
              this.rate[taskIdx] = task.requestTotal > 0 ?
                String(this.$_.round((task.complete / task.requestTotal) * 100)) + '%' : '0%';
            }
          })
          taskIdx++;
        })
        this.$_.forEach(this.imprChart.series, item => {
          item.data = this.$_.map(_result.data, item.id)
        })
        let xaxis = this.$_.map(_result.data, 'ibmTaskTypeName');
        let annotations = [];
        this.rate.forEach((r, idx) => {
          xaxis[idx] += (r ? '(' + r + ')' : '');
          annotations[idx] = {
            x: xaxis[idx],
            strokeDashArray: 0,
            borderColor: '#EEEEEE',
            borderWidth: 0,
            label: {
              borderColor: 'transparent',
              orientation: 'horizontal',
              style: {
                color: '#775DD0',
                fontSize: '12px',
                background: 'transparent',
              },
              text: r ? r : '',
              // text: r ? '이행률 : ' + r : '',
            }
          }
        })
        
        xaxis.forEach((x, idx) => {
          if (!annotations[idx]) {
            annotations[idx] = {
              x: x,
              strokeDashArray: 0,
              borderColor: '#EEEEEE',
              borderWidth: 0,
              // borderColor: '#775DD0',
              label: {
                borderColor: 'transparent',
                orientation: 'horizontal',
                style: {
                  color: '#775DD0',
                  fontSize: '12px',
                  background: 'transparent',
                },
                text: '',
              }
            }
          }
        })
        this.imprChart.chartOptions.xaxis.categories = xaxis;
        this.imprChart.chartOptions.annotations.xaxis = annotations;
        
        this.imprChart.chartWidth = '100%';
        this.$refs['imprChart'].refresh();
      },);
    },
    linkImprClick(row) {
      this.popupOptions.title = '[' + row.ibmTaskTypeName + '] 업무별 개선 이행률 상세'; // 업무별 개선 이행률 상세
      this.popupOptions.param = {
        tab: 'task',
        clickInfo: row.ibmTaskTypeName,
        data: row,
        searchParam: {
          plantCd: this.$store.getters.user.plantCd,
          actionCompleteRequestDates: this.grid.actionCompleteRequestDates,
          actionDeptCd: this.$store.getters.user.deptCd,
          ibmTaskTypeCd: row.ibmTaskTypeCd,
        }
      };
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprActionRateDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>